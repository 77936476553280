import React from 'react'
import {navigateTo} from 'gatsby-link'
import Gallery from 'react-photo-gallery'

import Layout from '../components/layout'
import JumboBackground from '../components/jumbo-background'
import CardBox from '../components/card-box'
import Card from '../components/card'
import Button from '../components/button'

import Abbotsford1 from '../images/Abbotsford/Abbotsford1.jpg'
import Abbotsford2 from '../images/Abbotsford/Abbotsford2.jpg'
import Abbotsford3 from '../images/Abbotsford/Abbotsford3.jpg'
import Abbotsford4 from '../images/Abbotsford/Abbotsford4_dark.jpg'
import Abbotsford5 from '../images/Abbotsford/Abbotsford5.jpg'
import Abbotsford6 from '../images/Abbotsford/Abbotsford6.jpg'
import Abbotsford7 from '../images/Abbotsford/Abbotsford7.jpg'

const photos = [

  {
    src: Abbotsford1,
    width: 1919,
    height: 1280
  },
  {
    src: Abbotsford2,
    width: 800,
    height: 1199
  },
  {
    src: Abbotsford3,
    width: 1919,
    height: 1280
  },
  {
    src: Abbotsford4,
    width: 1919,
    height: 1280
  },
  {
    src: Abbotsford5,
    width: 1919,
    height: 1280
  },
  {
    src: Abbotsford6,
    width: 800,
    height: 1199
  },
  {
    src: Abbotsford7,
    width: 1919,
    height: 1280
  }
]

const NelsonStreet = ({location}) => {
  return(
    <Layout path={location.pathname}>
      <JumboBackground
        background={Abbotsford3}
      />
      <CardBox title="Nelson Street, Abbotsford" noSpacer center>
        <Card>
          <Gallery photos={photos}/>
          <br/>
          <Button dark onClick={() => navigateTo('/projects')}>Back to projects</Button>
        </Card>
      </CardBox>
    </Layout>
  )
}

export default NelsonStreet
